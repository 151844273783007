import { MaybeRef, reactive, ref, Ref, unref } from "vue"

export interface NiceUiConfig {
  t: (key: string, value?: any) => string
  locale?: MaybeRef<string>
}

interface ProvidedNiceUiConfig {
  t: (key: string, value?: any) => string
  locale: string
  updateLocale: (newLocale: string) => void
}

const niceUiConfig: ProvidedNiceUiConfig = reactive({
  t: (key: string, value?: any) => key,
  locale: "de",
  updateLocale(newLocale: string) {
    this.locale = newLocale
  },
})

export function provideNiceUiConfig(config: NiceUiConfig) {
  niceUiConfig.t = config.t
  niceUiConfig.locale = unref(config.locale) ?? "de"
}

export function useNiceUi() {
  return niceUiConfig
}
